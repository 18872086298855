import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { setGameData, setIsFetching, setIsLoading, useGame } from '../../contexts/GameContext';
import useContract from '../../hooks/useContract';
import { RPS } from '../../abis/RPS';
import { getGameData } from '../../utils';
import { Card, CardHeader } from '../Card';
import { Button, Flex, Input, InputGroup, Spinner, Text, useToast } from '@chakra-ui/react';
import SelectMove from '../SelectMove/SelectMove';
import { isBigNumberish } from '@ethersproject/bignumber/lib/bignumber';
export default function Solve() {
    const { state: { isFetching, game }, dispatch, } = useGame();
    const [move, setMove] = React.useState();
    const [salt, setSalt] = React.useState();
    const toast = useToast({
        isClosable: true,
        position: 'top-right',
        duration: 5000,
        status: 'error',
    });
    const contract = useContract({ abi: RPS.abi, address: game.address });
    const onSubmit = useCallback(async (data) => {
        dispatch(setIsFetching(true));
        const { move, salt } = data;
        if (!isBigNumberish(salt))
            toast({ title: 'Error with salt, verify what you entered' });
        try {
            const txn = await contract.solve(move, salt);
            await txn.wait();
        }
        catch (e) {
            console.log(e);
        }
        finally {
            dispatch(setIsFetching(false));
            try {
                dispatch(setIsLoading(true));
                const [updatedGameData, playerOneWins, playerTwoWins] = await Promise.all([
                    getGameData(contract),
                    contract.win(move, game.j2.move),
                    contract.win(game.j2.move, move),
                ]);
                const result = playerOneWins ? 'Player 1' : playerTwoWins ? 'Player 2' : "It's a tie";
                if (result === 'Player 1') {
                    toast({
                        title: '🤑 You win!',
                        status: 'success',
                        description: `A total of ${game.stake.toNumber() * 2} ETH`,
                    });
                }
                else if (result === 'Player 2') {
                    toast({ title: 'You lose! Try again 👊', status: 'error' });
                }
                else {
                    toast({ title: "It's a tie!", status: 'info' });
                }
                updatedGameData.j1.move = Number(move);
                updatedGameData.j1.salt = salt;
                updatedGameData.result = result;
                dispatch(setGameData(updatedGameData));
            }
            finally {
                dispatch(setIsLoading(false));
            }
        }
    }, [contract, dispatch, game.j2.move]);
    return (_jsxs(Card, { children: [_jsx(CardHeader, { title: "Complete the game" }), _jsx(Flex, { direction: "column", p: 5, gap: 4, children: isFetching ? (_jsxs(Flex, { direction: "column", alignItems: "center", children: [_jsx(Text, { mb: 3, children: "Fetching results" }), _jsx(Spinner, { mb: 3 })] })) : (_jsxs(_Fragment, { children: [_jsxs(Flex, { w: "full", direction: "column", gap: 2, children: [_jsx("label", { htmlFor: "j1Move", children: "First move" }), _jsx(SelectMove, { id: "j1Move", value: move, placeholder: "Your first move", onChange: (e) => setMove(Number(e.target.value)) })] }), _jsxs(Flex, { w: "full", direction: "column", gap: 2, children: [_jsx("label", { htmlFor: "j1Move", children: "Salt" }), _jsx(InputGroup, { children: _jsx(Input, { fontWeight: "bold", placeholder: "Your salt", onChange: (e) => setSalt(e.currentTarget.value) }) })] }), _jsx(Button, { colorScheme: "teal", type: "submit", onClick: () => onSubmit({ move, salt: salt }), disabled: !move || !salt, children: "Reveal Your Move" })] })) })] }));
}
