import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Container, Flex, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Card, CardHeader } from '../components/Card';
import GameInfo from '../components/Game/GameInfo';
import GameRefund from '../components/Game/GameRefund';
import { useGame } from '../contexts/GameContext';
import useGameData from '../hooks/useGameData';
import GameStatus from '../components/Game/GameStatus';
import Actions from '../components/Game/Actions';
import { Helmet } from 'react-helmet';
import GameCreated from '../components/Game/GameCreated';
export default function Game() {
    const { contract } = useParams();
    useGameData({ address: contract });
    const { state: { game, gameCreated }, } = useGame();
    if (!!gameCreated.j1Move && !!gameCreated.salt)
        return _jsx(GameCreated, { move: gameCreated.j1Move, salt: gameCreated.salt, address: contract });
    return (_jsxs(Container, { maxW: "container.md", flexDirection: "column", gap: 3, children: [_jsx(Helmet, { children: _jsxs("title", { children: ["Game ", game.address] }) }), _jsxs(Flex, { gap: 5, direction: "column", children: [_jsx(Card, { children: _jsx(Box, { p: 5, children: _jsxs(Flex, { direction: ['column', 'row'], alignItems: "center", justifyContent: "space-between", gap: 4, children: [_jsxs(Box, { children: [_jsx(Text, { fontSize: "sm", fontWeight: "bold", mb: 1.5, children: "Status" }), _jsx(GameStatus, {})] }), _jsx(GameRefund, {})] }) }) }), _jsx(Actions, {}), _jsxs(Card, { children: [_jsx(CardHeader, { title: "Game Info" }), _jsx(Box, { px: 5, my: 5, children: _jsx(GameInfo, { game: game }) })] })] })] }));
}
