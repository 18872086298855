import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { RPS } from '../../abis/RPS';
import { setGameData, setIsFetching, setIsLoading, useGame } from '../../contexts/GameContext';
import useContract from '../../hooks/useContract';
import { getGameData } from '../../utils';
import { Card, CardHeader } from '../Card';
import SelectMove from '../SelectMove/SelectMove';
export default function Play() {
    const { state: { isFetching, game }, dispatch, } = useGame();
    const { stake, address } = game;
    const contract = useContract({ abi: RPS.abi, address });
    const [move, setMove] = React.useState();
    const onSubmit = useCallback(async (data) => {
        dispatch(setIsFetching(true));
        const { move } = data;
        if (!move)
            return dispatch(setIsFetching(false));
        try {
            const txn = await contract.play(move, {
                value: stake,
            });
            await txn.wait();
        }
        catch (err) {
            console.error('Error during play() transaction:', err);
        }
        finally {
            dispatch(setIsFetching(false));
            try {
                dispatch(setIsLoading(true));
                dispatch(setGameData(await getGameData(contract)));
            }
            finally {
                dispatch(setIsLoading(false));
            }
        }
    }, [contract, dispatch, stake]);
    return (_jsxs(Card, { children: [_jsx(CardHeader, { title: "Choose Your Move" }), _jsx(Box, { p: 5, children: _jsxs(Flex, { w: "full", direction: "column", alignItems: "center", gap: 2, children: [!isFetching ? (_jsx(SelectMove, { id: "j1Move", placeholder: "Choose a move", onChange: (e) => setMove(Number(e.currentTarget.value)) })) : (_jsxs(_Fragment, { children: [_jsx(Text, { children: "Submitting your move" }), _jsx(Spinner, { mb: 3 })] })), !isFetching && (_jsx(Button, { colorScheme: "teal", justifySelf: "center", onClick: () => onSubmit({ move }), disabled: !move, children: "Make a Move" }))] }) })] }));
}
