import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/index';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NewGame from './pages/NewGame';
import { DashboardLayout } from './components/Layouts/DashboardLayout';
import { Web3ReactProvider } from '@web3-react/core';
import Game from './pages/Game';
import Login from './pages/Login';
import { GameProvider } from './contexts/GameContext';
import { Helmet } from 'react-helmet';
import { metaMask, hooks as metaMaskHooks } from './utils/connectors/metamask';
import { walletConnect, hooks as walletConnectHooks } from './utils/connectors/walletconnect';
const connectors = [
    [metaMask, metaMaskHooks],
    [walletConnect, walletConnectHooks],
];
function App() {
    const connexionType = localStorage.getItem('connexionType') || false;
    useEffect(() => {
        if (connexionType === 'injected') {
            void metaMask.connectEagerly().catch(() => {
                console.debug('Failed to connect eagerly to metamask');
            });
        }
        else if (connexionType === 'walletconnect') {
            walletConnect.connectEagerly().catch(() => {
                console.debug('Failed to connect eagerly to walletconnect');
            });
        }
    }, []);
    return (_jsx(Web3ReactProvider, { connectors: connectors, children: _jsx(ChakraProvider, { theme: theme, children: _jsxs(GameProvider, { children: [_jsx(Helmet, { children: _jsx("title", { children: "RPSLS" }) }), _jsx(BrowserRouter, { children: _jsx(Routes, { children: _jsxs(Route, { path: "/", element: _jsx(DashboardLayout, {}), children: [_jsx(Route, { index: true, element: _jsx(NewGame, {}) }), _jsx(Route, { path: "/game/:contract", element: _jsx(Game, {}) }), _jsx(Route, { path: "/login", element: _jsx(Login, {}) })] }) }) })] }) }) }));
}
export default App;
