import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import { isAddress } from '@ethersproject/address';
import React from 'react';
import { useNavigate } from 'react-router-dom';
export default function JoinGame() {
    const [address, setAddress] = React.useState('');
    const navigate = useNavigate();
    return (_jsxs(Box, { children: [_jsx(Text, { fontSize: "lg", fontWeight: "bold", mt: 1, mb: 3, children: "Join a game" }), _jsxs(Flex, { alignItems: "center", justifyContent: "center", direction: "column", gap: 4, children: [_jsxs(Flex, { w: "full", direction: "column", gap: 2, children: [_jsx(Input, { type: "text", placeholder: "Enter game address", isInvalid: address.length > 6 && !isAddress(address), onChange: (e) => setAddress(e.currentTarget.value) }), address.length > 6 && !isAddress(address) && (_jsx(Text, { fontSize: "xs", color: "red.500", children: "Please enter a valid game address" }))] }), _jsx(Button, { colorScheme: "teal", isDisabled: !address || !isAddress(address), variant: "solid", onClick: () => navigate(`/game/${address}`), children: "Go to game" })] })] }));
}
