import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Portal, useDisclosure } from '@chakra-ui/react';
import { useWeb3Wallet } from '../../hooks/useWeb3Wallet';
import Metamask from '../../assets/svg/metamask.png';
import WalletConnect from '../../assets/svg/walletconnect.png';
import { Modal } from '../Modal';
import { useWeb3Network } from '../../hooks/useWeb3Network';
export default function ConnectWallet() {
    const { connect, loading, disconnect, active } = useWeb3Wallet();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { networkError } = useWeb3Network();
    return (_jsxs(Box, { children: [_jsx(Button, { colorScheme: "teal", onClick: () => (active ? disconnect() : onOpen()), isLoading: loading, variant: "outline", size: "md", children: !active ? 'Connect wallet' : 'Disconnect' }), _jsx(Portal, { children: _jsx(Modal, { isOpen: isOpen, onClose: onClose, title: "Connect wallet", maxW: "md", render: _jsxs(Flex, { alignItems: "center", px: 4, pb: 4, gap: 4, justifyContent: "center", direction: "column", children: [_jsxs(Button, { colorScheme: "orange", onClick: () => connect('injected').then(() => onClose()), isLoading: loading, variant: "outline", size: "md", gap: 3, children: [_jsx("img", { src: Metamask, width: "20px", height: "20px" }), "Metamask"] }), _jsxs(Button, { colorScheme: "blue", onClick: () => connect('walletconnect').then(() => onClose()), isLoading: loading, variant: "outline", size: "md", gap: 2, px: 3, children: [_jsx("img", { src: WalletConnect, width: "30px", height: "30px" }), "Wallet Connect"] })] }) }) })] }));
}
