import { ethers } from 'ethers';
import { useState, useEffect } from 'react';
import { Contract } from 'ethers';
import { useWeb3React } from '@web3-react/core';
export default function useContract({ abi, address }) {
    const { provider, chainId, account } = useWeb3React();
    const [contract, setContract] = useState(new ethers.Contract(address, abi, provider?.getSigner(account)));
    useEffect(() => {
        setContract(new Contract(address, abi, provider?.getSigner(account)));
    }, [address, account, chainId, provider]);
    return contract;
}
