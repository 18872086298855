import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Button, Flex, Input, InputGroup, InputLeftElement, InputRightElement, Tag, Text, useColorModeValue, } from '@chakra-ui/react';
import { useWeb3Wallet } from '../../hooks/useWeb3Wallet';
import { isAddress } from '@ethersproject/address';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import styled from 'styled-components';
import { isAddressEquals, useGetPlayerBalance } from '../../utils';
import SelectMove from '../SelectMove/SelectMove';
import { chakra } from '@chakra-ui/react';
import { FaEthereum } from 'react-icons/fa';
const RoundedJazzicon = styled(Jazzicon) `
    border-radius: 50%;
    padding: 4px;
`;
export default function NewGameForm({ onClick }) {
    const { account } = useWeb3Wallet();
    const [newGameData, setNewGameData] = React.useState({
        j1Move: 0,
        j2Address: '',
        stake: 0,
    });
    const balance = useGetPlayerBalance();
    const validatej2Address = useMemo(() => {
        if (newGameData.j2Address === account) {
            return false;
        }
        return true;
    }, [newGameData.j2Address, account]);
    const validateEthersBalance = useMemo(() => {
        if (newGameData.stake > balance) {
            return false;
        }
        return true;
    }, [newGameData.stake]);
    if (!account)
        return _jsx(_Fragment, {});
    return (_jsxs(Flex, { alignItems: "center", justifyContent: "center", direction: "column", gap: 4, children: [_jsxs(Flex, { w: "full", direction: "column", gap: 2, children: [_jsx(chakra.label, { fontWeight: "medium", htmlFor: "j1Move", children: "\u270D\uFE0F Your move" }), _jsx(SelectMove, { id: "j1Move", value: newGameData.j1Move, placeholder: "Choose a move", onChange: (e) => setNewGameData({ ...newGameData, j1Move: e.target.value }) })] }), _jsxs(Flex, { w: "full", direction: "column", gap: 2, children: [_jsx(chakra.label, { color: isAddressEquals(newGameData.j2Address, account) ||
                            (!!newGameData.j2Address && !isAddress(newGameData.j2Address))
                            ? 'red.500'
                            : useColorModeValue('black', 'white'), fontWeight: "medium", htmlFor: "j2Address", children: "\uD83D\uDC71 Player 2 address" }), _jsxs(InputGroup, { id: "j2Address", children: [isAddress(newGameData.j2Address) && (_jsx(InputLeftElement, { children: _jsx(RoundedJazzicon, { seed: jsNumberForAddress(newGameData.j2Address) }) })), _jsx(Input, { fontWeight: "bold", value: newGameData.j2Address, type: "text", isInvalid: isAddressEquals(newGameData.j2Address, account) ||
                                    (!!newGameData.j2Address && !isAddress(newGameData.j2Address)), placeholder: "Choose a player", onChange: (e) => setNewGameData({
                                    ...newGameData,
                                    j2Address: e.currentTarget.value,
                                }) })] }), isAddressEquals(newGameData.j2Address, account) && (_jsx(Text, { fontSize: "xs", color: "red.500", children: "You can't play against yourself" })), !!newGameData.j2Address && !isAddress(newGameData.j2Address) && (_jsx(Text, { fontSize: "xs", color: "red.500", children: "Please enter a correct wallet address" }))] }), _jsxs(Flex, { w: "full", direction: "column", gap: 2, children: [_jsx(chakra.label, { color: !validateEthersBalance ? 'red.500' : useColorModeValue('black', 'white'), fontWeight: "medium", htmlFor: "j2Address", children: "\uD83D\uDCB0 Stake" }), _jsxs(InputGroup, { children: [_jsx(Input, { fontWeight: "bold", value: newGameData.stake, type: "number", placeholder: "Choose a stake", isInvalid: !validateEthersBalance, onChange: (e) => setNewGameData({ ...newGameData, stake: Number(e.currentTarget.value) }), noOfLines: 1, pr: 32 }), _jsx(InputRightElement, { w: "fit-content", children: _jsxs(Flex, { children: [_jsx(Button, { onClick: () => setNewGameData({ ...newGameData, stake: balance }), variant: "link", fontWeight: "bold", mr: 2, _hover: {
                                                underline: 'none',
                                            }, bg: useColorModeValue('whiteAlpha.900', 'blackAlpha.900'), disabled: newGameData.stake === balance, children: "MAX" }), _jsx(Tag, { w: "full", as: "div", px: 3, fontWeight: "bold", children: _jsxs(Flex, { w: "auto", justify: "flex-start", alignItems: "center", gap: 1, children: [_jsx(FaEthereum, {}), " ETH"] }) })] }), mr: 3 })] }), !validateEthersBalance && (_jsx(Text, { fontSize: "xs", color: "red.500", children: "Your balance is too low" }))] }), _jsx(Button, { colorScheme: "teal", variant: "solid", isDisabled: !newGameData.j2Address ||
                    !validatej2Address ||
                    !newGameData.stake ||
                    !newGameData.j1Move ||
                    !validateEthersBalance, onClick: () => onClick && onClick(newGameData), children: "Create game" })] }));
}
