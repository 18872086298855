import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useReducer } from 'react';
import { parseEther } from '@ethersproject/units';
export const Moves = {
    1: '👊 Rock',
    2: '📃 Paper',
    3: '✂️ Scissors',
    4: '🦎 Lizard',
    5: '🖖 Spock',
};
export var Move;
(function (Move) {
    Move[Move["Null"] = 0] = "Null";
    Move[Move["Rock"] = 1] = "Rock";
    Move[Move["Paper"] = 2] = "Paper";
    Move[Move["Scissors"] = 3] = "Scissors";
    Move[Move["Lizard"] = 4] = "Lizard";
    Move[Move["Spock"] = 5] = "Spock";
})(Move || (Move = {}));
const initialState = {
    game: {
        address: '',
        j1: {
            address: '',
            commitment: '',
        },
        j2: {
            address: '',
            move: Move.Null,
        },
        stake: parseEther('0.001'),
        lastAction: new Date(0),
        result: '',
        timeout: 0,
    },
    gameCreated: {
        address: '',
        j1Move: Move.Null,
        salt: undefined,
    },
    isLoading: false,
    isError: false,
    isFetching: false,
};
export const GameContext = createContext({
    state: initialState,
    dispatch: () => { },
});
const gameReducer = (state, action) => {
    switch (action.type) {
        case 'SET_GAME':
            return {
                ...state,
                game: action.payload,
            };
        case 'SET_LOADING':
            return {
                ...state,
                isLoading: action.payload,
            };
        case 'SET_ERROR':
            return {
                ...state,
                isError: action.payload,
            };
        case 'SET_FETCHING':
            return {
                ...state,
                isFetching: action.payload,
            };
        case 'SET_GAME_CREATED':
            return {
                ...state,
                gameCreated: action.payload,
            };
        default:
            return state;
    }
};
export const GameProvider = ({ children }) => {
    const [state, dispatch] = useReducer(gameReducer, initialState);
    return _jsx(GameContext.Provider, { value: { state, dispatch }, children: children });
};
export const useGame = () => {
    const { state, dispatch } = React.useContext(GameContext);
    return {
        state,
        dispatch,
    };
};
export const setIsLoading = (value) => ({
    type: 'SET_LOADING',
    payload: value,
});
export const setIsFetching = (value) => ({
    type: 'SET_FETCHING',
    payload: value,
});
export const setGameData = (value) => ({
    type: 'SET_GAME',
    payload: value,
});
export const setError = (value) => ({
    type: 'SET_ERROR',
    payload: value,
});
export const setGameCreated = (value) => ({
    type: 'SET_GAME_CREATED',
    payload: value,
});
