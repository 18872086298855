import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { CgDanger, CgSandClock } from 'react-icons/cg';
import { useGame } from '../../contexts/GameContext';
import { getPlayerId, hasJ2Played, isGameDone } from '../../utils';
import { ImCheckmark, ImCross } from 'react-icons/im';
export const getGameStatusTag = (game) => {
    const isSettled = isGameDone(game);
    const hasPlayed = hasJ2Played(game);
    const playerId = getPlayerId(game);
    let status;
    if (!playerId) {
        status = isSettled ? (hasPlayed ? 'settled' : 'cancelled') : !hasPlayed ? 'actionNeeded' : 'pending';
    }
    else {
        status = isSettled
            ? hasPlayed
                ? 'settled'
                : 'cancelled'
            : playerId === 1 && hasPlayed
                ? 'actionNeeded'
                : playerId === 2 && !hasPlayed
                    ? 'actionNeeded'
                    : 'pending';
    }
    switch (status) {
        case 'settled':
            return {
                icon: ImCheckmark,
                color: 'green',
                label: 'Settled',
            };
        case 'cancelled':
            return {
                icon: ImCross,
                color: 'red',
                label: 'Cancelled',
            };
        case 'actionNeeded':
            return {
                icon: CgDanger,
                color: 'red',
                label: 'Action needed',
            };
        case 'pending':
            return {
                icon: CgSandClock,
                color: 'gray',
                label: 'Pending',
            };
    }
};
export const getGameStatus = (game) => {
    const isSettled = isGameDone(game);
    const hasPlayed = hasJ2Played(game);
    const playerId = getPlayerId(game);
    if (!playerId) {
        return isSettled ? (hasPlayed ? 'settled' : 'cancelled') : !hasPlayed ? 'actionNeeded' : 'pending';
    }
    return isSettled
        ? hasPlayed
            ? 'settled'
            : 'cancelled'
        : playerId === 1 && hasPlayed
            ? 'actionNeeded'
            : playerId === 2 && !hasPlayed
                ? 'actionNeeded'
                : 'pending';
};
export default function GameStatus({ ...props }) {
    const { state: { game }, } = useGame();
    const { icon, color, label } = getGameStatusTag(game);
    return (_jsx(Box, { ...props, children: _jsxs(Tag, { size: "lg", variant: "subtle", colorScheme: color, children: [_jsx(TagLeftIcon, { boxSize: "12px", as: icon }), _jsx(TagLabel, { children: label })] }) }));
}
