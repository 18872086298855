import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { chakra, Flex } from '@chakra-ui/react';
import { Heading } from '@chakra-ui/react';
export const HeaderWrapper = chakra('header', {
    baseStyle: {
        height: '80px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 6,
        bg: 'white',
    },
});
export const LogoWrapper = ({ name }) => (_jsxs(Flex, { alignItems: "center", gap: 3, children: [_jsx("img", { src: "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='.9em' font-size='90'>\uD83D\uDD96</text></svg>", width: "30px", height: "30px", alt: "" }), _jsx(Heading, { display: ['none', '', 'block'], as: "h1", size: "md", textTransform: "uppercase", children: name })] }));
