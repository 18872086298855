import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, chakra, Flex, Heading, Tooltip } from '@chakra-ui/react';
import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';
import { MdHelpOutline } from 'react-icons/md';
const CardWrapper = chakra(Box, {
    baseStyle: {
        borderRadius: 16,
        border: 'solid 1px',
    },
});
const StyledCardHeader = styled(Box) `
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    .titleCardHeader {
        grid-column-start: ${({ children }) => (React.Children.toArray(children).length > 1 ? 2 : 1)};
        justify-content: ${({ children }) => (React.Children.toArray(children).length === 1 ? 'start' : 'center')};
    }
`;
export const BackButton = ({ onClick }) => {
    return (_jsx(Box, { _hover: { cursor: 'pointer', opacity: 0.8 }, onClick: onClick, children: _jsx(FaArrowLeft, { size: 22 }) }));
};
export const HelpButton = () => {
    return (_jsx(Tooltip, { hasArrow: true, placement: "bottom", label: "Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.", children: _jsx("span", { children: _jsx(MdHelpOutline, { size: 23 }) }) }));
};
export const CardHeader = ({ title, iconLeft, iconRight }) => {
    return (_jsxs(StyledCardHeader, { pt: 5, px: 5, children: [iconLeft && iconLeft, _jsx(Flex, { className: "titleCardHeader", children: _jsx(Heading, { as: "h2", size: "md", children: title }) }), iconRight && _jsx(Flex, { justifyContent: "flex-end", children: iconRight })] }));
};
export const Card = ({ children, header, ...props }) => {
    return (_jsxs(CardWrapper, { ...props, children: [header && header, children] }));
};
