import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { RPS } from '../../abis/RPS';
import useContract from '../../hooks/useContract';
import { setGameData, setIsFetching, setIsLoading, useGame } from '../../contexts/GameContext';
import { Button, Spinner, Tooltip, useInterval } from '@chakra-ui/react';
import { canJ1Refund, canJ2Refund, getGameData, getPlayerId, hasJ2Played, isGameDone } from '../../utils';
import { Link, useNavigate } from 'react-router-dom';
export function secondsUntilTimeout({ lastAction, timeout }, currentDate) {
    return Math.round((timeout - (currentDate.getTime() - lastAction.getTime())) / 1000);
}
function secondsToTime(e) {
    const m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, '0');
    const s = Math.floor(e % 60)
        .toString()
        .padStart(2, '0');
    return m + ':' + s;
}
function InactiveRefundButton({ handleDone }) {
    const { state: { game }, } = useGame();
    const [timeoutInSeconds, setTimeoutInSeconds] = useState(secondsUntilTimeout(game, new Date()));
    useInterval(() => {
        const secondsLeft = secondsUntilTimeout(game, new Date());
        if (secondsLeft >= 0) {
            setTimeoutInSeconds(secondsLeft);
        }
        else {
            handleDone && handleDone();
        }
    }, 1000);
    return (_jsx(Tooltip, { placement: "top", shouldWrapChildren: true, hasArrow: true, label: "Opponent has 5 minutes to play", children: _jsxs(Button, { isDisabled: true, colorScheme: "teal", children: ["Cancel available in ", secondsToTime(timeoutInSeconds)] }) }));
}
export default function GameRefund() {
    const navigate = useNavigate();
    const { state: { game, isLoading, isFetching }, dispatch, } = useGame();
    const contract = useContract({ abi: RPS.abi, address: game.address });
    const gameDone = isGameDone(game);
    const playerId = getPlayerId(game);
    const currentDate = new Date();
    const [canClaimTimeout, setCanClaimTimeout] = useState(playerId === 1 ? canJ1Refund(game, currentDate) : canJ2Refund(game, currentDate));
    const handleTimeout = useCallback(() => {
        setCanClaimTimeout(true);
    }, [setCanClaimTimeout]);
    const handleClaimTimeout = useCallback(async () => {
        try {
            dispatch(setIsFetching(true));
            const action = playerId === 1 ? contract.j2Timeout() : contract.j1Timeout();
            const txn = await action;
            await txn.wait();
        }
        catch (err) {
            console.error('Error on claim timeout', err);
        }
        finally {
            dispatch(setIsFetching(false));
            try {
                dispatch(setIsLoading(true));
                dispatch(setGameData(await getGameData(contract)));
            }
            finally {
                dispatch(setIsLoading(false));
            }
        }
    }, [contract, dispatch, playerId]);
    if (gameDone || !playerId)
        return (_jsx(Link, { to: "/", children: _jsx(Button, { colorScheme: "teal", children: "Create new game" }) }));
    if (playerId === 1 && hasJ2Played(game))
        return _jsx(_Fragment, {});
    if (playerId === 2 && !hasJ2Played(game))
        return _jsx(_Fragment, {});
    return !isLoading ? (canClaimTimeout ? (_jsx(Tooltip, { hasArrow: true, label: "Cancel game and retrieve your stake", children: _jsx(Button, { colorScheme: "teal", disabled: isFetching, isLoading: isFetching, onClick: handleClaimTimeout, children: "Cancel game" }) })) : (_jsx(InactiveRefundButton, { handleDone: () => setCanClaimTimeout(true) }))) : (_jsx(Spinner, {}));
}
