import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Code, Flex, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { useToast } from '@chakra-ui/react';
export default function InfoWrapper({ title, content, important, winner }) {
    const [showCopy, setShowCopy] = useState(false);
    const toast = useToast({
        title: `${title} copied to clipboard.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        id: content,
    });
    const copyToClipboard = () => {
        navigator.clipboard.writeText(content);
        if (!toast.isActive(content))
            toast();
    };
    return (_jsxs(Flex, { direction: "column", children: [_jsx(Text, { fontSize: "lg", fontWeight: "medium", textAlign: "center", mb: 3, children: title }), _jsxs(Code, { onMouseEnter: () => setShowCopy(true), onMouseLeave: () => setShowCopy(false), colorScheme: important ? 'red' : winner ? 'whatsapp' : 'teal', p: 6, cursor: "pointer", onClick: copyToClipboard, borderRadius: 8, position: "relative", children: [_jsx(Text, { noOfLines: 1, fontSize: "md", fontWeight: "bold", textAlign: "center", children: content }), showCopy && _jsx(FaRegCopy, { size: 15, style: { position: 'absolute', top: 10, right: 10 } })] })] }));
}
