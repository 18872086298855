import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LogoWrapper } from './styled';
import { ColorModeSwitcher } from '../ColorMode/ColorModeSwitcher';
import { Box, chakra, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useWeb3Wallet } from '../../hooks/useWeb3Wallet';
import { useDisclosure } from '@chakra-ui/react';
import { Modal } from '../Modal';
import { useNavigate } from 'react-router-dom';
import ConnectWallet from '../ConnectWallet';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { useGetPlayerBalance } from '../../utils';
export const Header = () => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { account } = useWeb3Wallet();
    const navigate = useNavigate();
    return (_jsxs(_Fragment, { children: [_jsxs(chakra.header, { shadow: "lg", h: "80px", display: "flex", alignItems: "center", justifyContent: "space-between", px: [4, 6], borderBottom: "solid 2px", borderColor: useColorModeValue('rgba(255,255,255, 1)', 'rgba(255,255,255,0.15)'), children: [_jsx(Box, { cursor: "pointer", onClick: () => navigate('/'), children: _jsx(LogoWrapper, { name: "RPSLS" }) }), _jsxs(Flex, { alignItems: "center", gap: 4, children: [_jsx(ColorModeSwitcher, { "aria-label": "" }), !!account && (_jsx(Flex, { cursor: "pointer", alignItems: "center", onClick: onOpen, children: _jsx(Jazzicon, { diameter: 35, seed: jsNumberForAddress(account) }) })), _jsx(ConnectWallet, {})] })] }), _jsx(Modal, { isOpen: isOpen, onClose: onClose, title: "Your account", render: _jsxs(Box, { mb: 4, children: [_jsxs(Text, { noOfLines: 1, children: ["\uD83D\uDC71 Address : ", account] }), _jsxs(Text, { children: ["\uD83E\uDD11 Balance : ", useGetPlayerBalance(), " ETH"] })] }) })] }));
};
