import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Container, Flex } from '@chakra-ui/react';
import { CgArrowRight } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { Move, Moves, setGameCreated, useGame } from '../../contexts/GameContext';
import { Card } from '../Card';
import InfoWrapper from './InfoWrapper';
export default function GameCreated({ move, salt, address }) {
    const navigate = useNavigate();
    const { dispatch } = useGame();
    const handleNavigation = () => {
        dispatch(setGameCreated({
            j1Move: Move.Null,
            address: address,
            salt: undefined,
        }));
        navigate(`/game/${address}`);
    };
    return (_jsx(Container, { maxW: "container.md", flexDirection: "column", children: _jsx(Card, { children: _jsxs(Flex, { direction: "column", alignItems: "stretch", gap: 10, p: 5, children: [_jsx(InfoWrapper, { title: "Game address", content: address }), _jsx(InfoWrapper, { title: "Your move", content: Object.values(Moves)[move - 1] }), _jsx(InfoWrapper, { title: "Your Salt (You'll need it to solve the game)", important: true, content: salt._hex }), _jsx(Button, { alignItems: "center", onClick: handleNavigation, colorScheme: "teal", rightIcon: _jsx(CgArrowRight, {}), children: "Go to game" })] }) }) }));
}
