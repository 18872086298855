import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useGame } from '../../contexts/GameContext';
import { getPlayerId, hasJ2Played, isGameDone } from '../../utils';
import Play from './Play';
import Solve from './Solve';
export default function Actions() {
    const { state: { game }, } = useGame();
    const hasPlayed = hasJ2Played(game);
    const gameDone = isGameDone(game);
    const playerId = getPlayerId(game);
    if (!playerId)
        return _jsx(_Fragment, {});
    if (playerId === 2 && !hasPlayed && !gameDone)
        return _jsx(Play, {});
    return _jsx(_Fragment, { children: playerId === 1 && hasPlayed && !gameDone && _jsx(Solve, {}) });
}
