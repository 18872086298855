import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useColorMode, useColorModeValue, IconButton } from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';
export const ColorModeSwitcher = ({ ...props }) => {
    const { toggleColorMode } = useColorMode();
    const text = useColorModeValue('dark', 'light');
    const SwitchIcon = useColorModeValue(FaMoon, FaSun);
    return (_jsx(IconButton, { ...props, fontSize: "lg", "aria-label": `Switch to ${text} mode`, variant: "ghost", color: "current", onClick: toggleColorMode, icon: _jsx(SwitchIcon, {}) }));
};
