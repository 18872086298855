import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Container, Flex, Heading, Spinner, Text } from '@chakra-ui/react';
import { parseEther } from '@ethersproject/units';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Hasher } from '../abis/Hasher';
import { RPS } from '../abis/RPS';
import { Card, CardHeader } from '../components/Card';
import JoinGame from '../components/Game/JoinGame';
import NewGameForm from '../components/Game/NewGameForm';
import { setGameCreated, useGame } from '../contexts/GameContext';
import useContract from '../hooks/useContract';
import useContractFactory from '../hooks/useContractFactory';
import { randomSalt } from '../utils';
export default function NewGame() {
    const hasher = useContract(Hasher);
    const rps = useContractFactory(RPS);
    const navigate = useNavigate();
    const [gameLoading, setGameLoading] = React.useState(false);
    const { dispatch } = useGame();
    const handleNewGame = useCallback(async ({ j1Move, j2Address, stake }) => {
        setGameLoading(true);
        const salt = randomSalt();
        if (!hasher || !rps)
            return setGameLoading(false);
        try {
            const j1Hash = await hasher.hash(j1Move, salt);
            const contract = await rps.deploy(j1Hash, j2Address, {
                value: parseEther(stake.toString()),
                gasLimit: 1000000,
            });
            await contract.deployed();
            dispatch(setGameCreated({ address: contract.address, salt: salt, j1Move }));
            navigate(`/game/${contract.address}`, { state: { salt: salt, move: j1Move } });
            setGameLoading(false);
        }
        catch (err) {
            setGameLoading(false);
        }
    }, [hasher, rps]);
    return (_jsx(_Fragment, { children: _jsxs(Container, { maxW: "container.md", children: [_jsxs(Text, { fontSize: "xl", fontWeight: "bold", mb: 1, children: ["Play the Web3 version of the famous game created by Sheldon Cooper. ", _jsx("br", {})] }), _jsx(Text, { fontSize: "md", fontWeight: "medium", mb: 6, children: "Choose a move, your opponent, a stake price, and you're all set." }), _jsxs(Card, { children: [!gameLoading && _jsx(CardHeader, { title: "Create a game" }), _jsx(Flex, { direction: "column", px: 5, py: 5, children: !gameLoading ? (_jsxs(_Fragment, { children: [_jsx(NewGameForm, { onClick: handleNewGame }), _jsx(Text, { fontWeight: "bold", textAlign: "center", mt: 3, children: "OR" }), _jsx(JoinGame, {})] })) : (_jsxs(Flex, { alignItems: "center", justifyContent: "center", direction: "column", gap: 4, children: [_jsx(Heading, { fontSize: "lg", children: "Creating game" }), _jsx(Spinner, {})] })) })] })] }) }));
}
