import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Box, Spinner } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { useWeb3Network } from '../../hooks/useWeb3Network';
import { useWeb3Wallet } from '../../hooks/useWeb3Wallet';
import Login from '../../pages/Login';
import SwitchNetwork from '../SwitchNetwork';
import { Header } from './Header';
export const DashboardLayout = () => {
    const { active, loading } = useWeb3Wallet();
    const { networkError } = useWeb3Network();
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsx(Flex, { flex: 1, h: "full", children: _jsx(Box, { width: "100%", h: "full", px: 5, py: 8, children: networkError ? _jsx(SwitchNetwork, {}) : loading ? _jsx(Spinner, {}) : active ? _jsx(Outlet, {}) : _jsx(Login, {}) }) })] }));
};
