import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Tag } from '@chakra-ui/react';
import { Move, Moves } from '../../contexts/GameContext';
import { useWeb3Wallet } from '../../hooks/useWeb3Wallet';
import { isGameDone, useEtherPrice } from '../../utils';
import { formatEther } from '@ethersproject/units';
import InfoWrapper from './InfoWrapper';
import { getGameStatus } from './GameStatus';
const Owner = ({ title }) => (_jsxs(Flex, { as: "span", direction: "row", justifyContent: "center", gap: 2, alignItems: "center", children: [title, _jsx(Tag, { children: "You" })] }));
export default function GameInfo({ game }) {
    const { account } = useWeb3Wallet();
    const gameDone = isGameDone(game);
    const status = getGameStatus(game);
    const etherPrice = useEtherPrice();
    return (_jsxs(Flex, { direction: "column", gap: 4, children: [gameDone && status !== 'cancelled' && !!game.result && (_jsx(InfoWrapper, { winner: true, title: "\uD83C\uDFC6 Winner", content: game.result })), _jsx(InfoWrapper, { title: "\uD83D\uDCB0 Stake", content: `${formatEther(game.stake)} ETH ${!!etherPrice && Number(formatEther(game.stake)) > 0
                    ? `(\u2248 ${(Number(formatEther(game.stake)) * etherPrice).toFixed(2)}$)`
                    : ''}` }), _jsx(InfoWrapper, { title: account === game.j1.address ? _jsx(Owner, { title: "\uD83D\uDC71 Player one" }) : `👱 Player one`, content: game.j1.address }), _jsx(InfoWrapper, { title: account === game.j2.address ? _jsx(Owner, { title: "\uD83D\uDC71 Player two" }) : `👱 Player two`, content: game.j2.address }), _jsx(InfoWrapper, { title: "#\uFE0F\u20E3 Commitment", content: game.j1.commitment }), _jsx(InfoWrapper, { title: "\u23F0 Last action", content: game.lastAction.toLocaleString() }), _jsx(InfoWrapper, { title: "\u270D\uFE0F Player 2 move", content: game.j2.move === Move.Null ? '⌛️ Pending' : Object.values(Moves)[game.j2.move - 1] })] }));
}
